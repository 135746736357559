import { Link as ScrollLink } from "react-scroll";
import React from "react"; 
import SearchComponent1 from "./SearchComponent1";
import a from '../Home/fullfillheroimg.jpg'
import LazyLoad from "react-lazyload";

export default function HeroSection() {
  const closeMenu = () => {
    // Add any code to close the menu if needed
  };
  return (

<div className="hero-container1 container-fluid">
        <img
          className="hero-image"
          src={a} // Replace with the actual path to your image
          alt="Hero Image"
        />
   
      
            <center > 
         <div className="divhero"  >
         <SearchComponent1/>
         </div>
         </center>
        
      {/* Add other content or components for the home page */}
    </div>
   
  );
}