// import React from 'react';
// import './QuickLink.css'; 

// const ConsultationCard = () => {
//   return (
//     <div className="consultation-card">
//       <p>Start your consultation here</p>
//       <p>Book your appointment now</p>
//       <button className="book-now-button">Book Now</button>
//     </div>
//   );
// };

// export default ConsultationCard;


import React from 'react';
import './QuickLink.css';
import { useNavigate } from 'react-router-dom';


const ConsultationCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    
    navigate('/Main-Services');
  };

 return (
    <div className="card">
      <div className="card-content">
        <div className="text-container">
         <p style={{color:"white"}}>Start Your Consultation Here</p>
          <h2 style={{color:"white"}}>Book Your Appointment Now</h2><br/>
        </div>
        
        <a href="/Main-Services"><button
      type="button"
      className="btn custom-btn quick-btn"
     
    >
      Book Now
    </button>
    </a>
      </div>
    </div>
 );
};

export default ConsultationCard;


