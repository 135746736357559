import "./App.css";
import AbcServices from "./Pages/Home/AbcServices";
import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ServiceComponent from "./Pages/Home/ServiceComponet";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import StartupServices from "./StartupServices";
import AccountingServices from "./Pages/Home/AccountingServices";
import GSTServices from "./Pages/Home/GST-Services";
import AboutUs from "./Pages/Home/About-us";
import { MainServices } from "./Pages/Home/MainServices";
import Page from "./Pages/Home/AccountingServices";
import ROCServices from "./Pages/Home/ROCServices";
import Itroc from "./Pages/Home/ITROC-TaxEdit";
import AuditandRoc from "./Pages/Home/AuditandRoc";
import TDS from "./Pages/Home/TDSSaleofproperty";
import IncomeTax from "./Pages/Home/IncomeTax";
import ServiceFilter from "./Pages/Home/ServiceFilter";
import GSTService1 from "./Pages/Home/GST1";
import GSTPublicPrivate from "./Pages/Home/GStPublicPrivate";
import GSTLLP from "./Pages/Home/GSTServiceLLP";
import GSTIndiviual from "./Pages/Home/GSTIndiviual";
import Incometaxprivate from "./Pages/Home/Incometaxforprivatelimitedcompany";
import AadhaarPANLinkingTable from "./Pages/Home/Panaadharlink";
import RevisedIncomeTaxReturn from "./Pages/Home/RevisedIncomeTaxReturn";
import SalariedIndiviual from "./Pages/Home/SalariedIndiviual";
import NRIInformation from "./Pages/Home/NRITaxation";
import CapitalGainTaxation from "./Pages/Home/CapitalGain-Taxation";
import Privacy from "./Pages/Home/Privacy";
import TermsandConditions from "./Pages/Home/TermsandConditions";
import RefundPolicy from "./Pages/Home/RefundPolicy";
import GSTServicesd from "./Pages/Home/GSTSERVICESDUPLICATE";
import IncomeBussinessprofession from "./Pages/Home/IncomeFromBussinessProfession";
import SalaryTaxation from "./Pages/Home/SalaryTaxation";
import IncomeFromHouseproperty from "./Pages/Home/IncomeFromHouseProperty";
import Gstservicesquarterly from "./Pages/Home/GSTServicesquarterly";
import ConsultationCard from "./Pages/Home/QuickLink";
import Gstservicesmonthly from "./Pages/Home/GSTServiceMonthly";
import GSTPrivateltdcompany from "./Pages/Home/GSTPrivateltdcompany";
import GSTService2 from "./Pages/Home/GST2";
import ContactMe from "./Pages/Home/ContactMe";
import ContactForm from "./Pages/Home/ContactForm";


import FreeConsultancy from "./Pages/Home/appointmnetpages/FreeConsultancy";

import RevisedIncomeTaxReturn1 from "./Pages/Home/appointmnetpages/IncomeTaxServices/RevisedIncomeTaxReturn";
import PanAadharLink from "./Pages/Home/appointmnetpages/IncomeTaxServices/PanAadharLink";
import IncomeTaxFiling from "./Pages/Home/appointmnetpages/IncomeTaxServices/IncomeTaxFiling";

import SalaryTaxation1 from "./Pages/Home/appointmnetpages/IncomeTaxServices/SalaryTaxation1";
import SalariedIndividual from "./Pages/Home/appointmnetpages/IncomeTaxServices/SalariedIndividual";
import Nritaxation from "./Pages/Home/appointmnetpages/IncomeTaxServices/Nritaxation";
import CapitalGainTaxation1 from "./Pages/Home/appointmnetpages/IncomeTaxServices/CapitalGainTaxation";
import IncomefromotherHouse from "./Pages/Home/appointmnetpages/IncomeTaxServices/IncomefromotherHouse";
import TDSreturn from "./Pages/Home/appointmnetpages/TDSreturn";
import GSTBookkeepingOnepersonCompanyYearly from "./Pages/Home/appointmnetpages/GstServices/GSTBookkeepingOnepersonCompanyYearly";
import GSTbookkeepingQuarterly from "./Pages/Home/appointmnetpages/GstServices/GSTbookkeepingQuarterly";
import GSTbookkepingMonthly from "./Pages/Home/appointmnetpages/GstServices/GSTbookkepingMonthly";
import GSTBookkeepingOnepersonCompanyMonthly from "./Pages/Home/appointmnetpages/GstServices/GSTBookkeepingOnepersonCompanyMonthly";
import GSTBookkeepingOnepersonCompanyQuarterly from "./Pages/Home/appointmnetpages/GstServices/GSTBookkeepingOnepersonCompanyQuarterly";
import GSTregistrationSoleProprieter from "./Pages/Home/appointmnetpages/GstServices/GSTregistrationSoleProprieter";
import GSTregistrationPartnershipfirm from "./Pages/Home/appointmnetpages/GstServices/GSTregistrationPartnershipfirm";
import GSTregistrationforCompany from "./Pages/Home/appointmnetpages/GstServices/GSTregistrationforCompany";
import AccountingServicesbook from "./Pages/Home/appointmnetpages/AccountingServicesbook";
import LLpAnnual from "./Pages/Home/appointmnetpages/RocServices/LLpAnnual";
import RocPrivate from "./Pages/Home/appointmnetpages/RocServices/RocPrivate";
import Footer from "./Pages/Home/Footer";
import IncomeForBusiness from "./Pages/Home/appointmnetpages/IncomeTaxServices/IncomeForBusiness";



function App() {
  
  return (
    <div className="App">
      <Router>
        <div>
      <Navbar/>
          

          <Routes>
          <Route path="/" element={<Home />}></Route>
<Route  path="/accounting-service-appointment" element={<AccountingServicesbook/>}></Route>
<Route path="/LLP Annual Compliance" element={<LLpAnnual/>}></Route>
<Route path="/consultancy" element={<FreeConsultancy/>}></Route>
<Route path="/Audit and ROC-Private Limited-appointment" element={<RocPrivate/>}></Route>


<Route path="/GstRegistrationForCompany-appointment" element={<GSTregistrationforCompany/>}></Route>
<Route path="/GSTregistrationPartnershipfirm-appointment" element={<GSTregistrationPartnershipfirm/>}></Route>
<Route path="/GSTregistrationSoleProprieter-appointment" element={<GSTregistrationSoleProprieter/>}></Route>
<Route path="/GSTBookkeepingOnepersonCompanyQuarterly-appointment" element={<GSTBookkeepingOnepersonCompanyQuarterly/>}></Route>
<Route path="/GSTBookkeepingOnepersonCompanyMonthly-appointment" element={<GSTBookkeepingOnepersonCompanyMonthly/>}></Route>
<Route path="/GSTBookkeepingOnepersonCompanyYearly-appointment" element={<GSTBookkeepingOnepersonCompanyYearly/>}></Route>
<Route path="/GSTBookkeeping-monthly-appointment" element={<GSTbookkepingMonthly/>}></Route>
<Route path="/GSTBookkeeping-quarterly-appointment" element={<GSTbookkeepingQuarterly/>}></Route>
<Route path="/tdsreturn-appointment" element={<TDSreturn/>}></Route>
<Route path="/IncomefromotherHouse-appointment" element={<IncomefromotherHouse/>}></Route>
<Route path="/Capitalgaintaxation-appointment" element={<CapitalGainTaxation1/>}></Route>
<Route path="/Nritaxation-appointment" element={<Nritaxation/>}></Route>
<Route path="/salaried-individual-appointment" element={<SalariedIndividual/>}></Route>
<Route path="/SalaryTaxation-appointment" element={<SalaryTaxation1/>}></Route>
<Route path="/RevisedIncomeTaxReturn-appointment" element={<RevisedIncomeTaxReturn1/>}></Route>

<Route path="/panaadharlink-appointment" element={<PanAadharLink/>}></Route>
<Route path="/IncomeTaxFiling-appointment" element={<IncomeTaxFiling/>}></Route>
<Route path="/IncomeForBusiness-Profession-appointment" element={<IncomeForBusiness/>}></Route>


          <Route path="/service-filter" element={<ServiceFilter />}></Route>
          <Route path="/contact" element={<ContactForm/>}></Route>

           
            <Route path="/a" element={<AbcServices />}></Route>

            <Route path="*" element={<Home/>}></Route>
            {/* <Route path="/Services" element={<StartupServices/>}></Route> */}
            <Route path="/service-component" element={<ServiceComponent/>}></Route>

            <Route path="/Accounting-Services" element={<Page/>}></Route>
            <Route path="/GST-Services" element={<GSTServices/>}></Route>
            <Route path="/GST-Services-ALL" element={<GSTServicesd/>}></Route>
            <Route path="/Quick-Link" element={<ConsultationCard/>}></Route>

            <Route path="/About-us" element={<AboutUs/>}></Route>
            <Route path="/Main-Services" element={<MainServices/>}></Route>
            <Route path="/ROC-Services" element={<ROCServices/>}></Route>
            <Route path="/IT-ROC Tax-Audit" element={<Itroc/>}></Route>
            <Route path="/Audit-ROC" element={<AuditandRoc/>}></Route>
            <Route path="/TDS-SaleOfProperty" element={<TDS/>}></Route>
            <Route path="/IncomeTax-Service" element={<IncomeTax/>}></Route>
            <Route path="/GST-Bookkeeping-Quarterly-Pvt-Ltd-Company" element={<GSTService1/>}></Route>
            <Route path="/IncomeTax-Private-Limited-Company" element={<Incometaxprivate/>}></Route>
            <Route path="/GST-Registration-for-public-private-Indian-Foreign" element={<GSTPublicPrivate/>}></Route>
            <Route path="/GST-Registration-for-Partnership-Including-LLP" element={<GSTLLP/>}></Route>
            <Route path="/GST-Registration-Sole-Propreitor" element={<GSTIndiviual/>}></Route>
            <Route path="/NRI-Taxation" element={<NRIInformation/>}></Route>
            <Route path="/Privacy-Policy" element={<Privacy/>}></Route>
            <Route path="/Refund-Policy" element={<RefundPolicy/>}></Route>
            <Route path="/Income-From-Bussiness-Profession" element={<IncomeBussinessprofession/>}></Route>
            <Route path="/Salary-Taxation" element={<SalaryTaxation/>}></Route>
            <Route path="/Income-From-House-Property" element={<IncomeFromHouseproperty/>}></Route>
            <Route path="/GST-Services-Quarterly" element={<Gstservicesquarterly/>}></Route>
            <Route path="/GST-Bookkeeping-Montly-Pvt-Ltd-Company" element={<GSTService2/>}></Route>

            <Route path="/GST-Services-Monthly" element={<Gstservicesmonthly/>}></Route>
            <Route path="/GST-Services-Private-Limited-Company" element={<GSTPrivateltdcompany/>}></Route>

            <Route path="/Capital-Gain-Taxation" element={<CapitalGainTaxation/>}></Route>
            <Route path="/Terms-and-Conditions" element={<TermsandConditions/>}></Route>


            <Route path="/PAN-AAdhar-Linking" element={<AadhaarPANLinkingTable/>}></Route>
            <Route path="/Revised-IncomeTax-Return" element={<RevisedIncomeTaxReturn/>}></Route>
            <Route path="/Salaried-Indiviual" element={<SalariedIndiviual/>}></Route>







        
          </Routes>
        </div>
      </Router>
     
    </div>
  );
}

export default App;
