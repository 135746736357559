import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MyContextProvider } from "./ContextOne";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>      
<MyContextProvider>
    <App />
 
    </MyContextProvider>
  </React.StrictMode>
);
