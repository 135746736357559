import React from 'react';
// import './Footer1.css'
function Footer() {
  const style1 = {
    color: "white",
    fontWeight: "500",
    textDecoration: "none",
    backgroundColor: "transparent",
   
  };
  
  return (
   <>
   <footer style={{  background:"#79848B"}}>
  <div class="container pt-5 border-bottom">
    <div class="row" >
      <div class="col-md-3 col-sm-12 mb-3 text-center" >
     <a href="/"> <img src='./img/ff.png' /></a>
      </div> 
      <div class="col-md-9 col-sm-12">

     

        <div class="col-md-4 col-sm-6 col-6 p-0 mb-3 float-left">
          <h5 class="mb-4 " style={{color:"white"}}>Quick links</h5>
        <ul class="list-group"  >
          <li class="list-group-item bg-transparent border-0 p-0 mb-2" ><a href="/" style={{color:"white",textDecoration:"none"}}>Home</a></li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2" ><a href="/Main-Services"  style={{color:"white",textDecoration:"none"}}>Services</a></li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2" ><a href="/contact"  style={{color:"white",textDecoration:"none"}}>Contact us</a></li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2"><a href="Privacy-Policy"  style={{color:"white",textDecoration:"none"}}>Privacy-policy</a></li>
        
          <li class="list-group-item bg-transparent border-0 p-0 mb-2" ><a href="Terms-and-Conditions"  style={{color:"white",textDecoration:"none"}}>Terms & Conditions
</a></li>
<li class="list-group-item bg-transparent border-0 p-0 mb-2" ><a href="Refund-Policy"  style={{color:"white",textDecoration:"none"}}>Refunds-Cancellations</a></li>
        </ul>
        </div>

      

        <div class="col-md-4 col-sm-6 col-6 mb-3 p-0 float-left">
          <h5 class="mb-4 " style={{color:"white"}}>Connect</h5>
        <ul class="list-group" style={style1}>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2">
            <a href="https://www.linkedin.com/company/fullfillcompliancelimited/"  style={{color:"white",textDecoration:"none"}}><i class="fa-brands fa-linkedin"></i>&nbsp;&nbsp; LinkedIn</a>
          </li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2">
            <a href="https://twitter.com/FCompliances"  style={{color:"white",textDecoration:"none"}}><i class="fa-brands fa-twitter"  style={{color:"white",textDecoration:"none"}}></i>&nbsp;&nbsp;Twitter</a>       
          </li >
          
          
      
          <li class="list-group-item bg-transparent border-0 p-0 mb-2">
            <a href="https://www.facebook.com/FullfillCompliance/" target="_blank"  style={{color:"white",textDecoration:"none"}}><i class="fa-brands fa-facebook"></i>&nbsp;&nbsp; Facebook</a>
          </li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2">
            <a href="https://www.youtube.com/" target="_blank"  style={{color:"white",textDecoration:"none"}}><i class="fa-brands fa-youtube"></i>&nbsp;&nbsp; YouTube</a>
          </li>
        </ul>
        </div>
        <div class="col-md-4 col-sm-6 col-6 mb-3 p-0 float-left">
          <h5 class="mb-4" style={{color:"white"}}>Contact</h5>
        <ul class="list-group" style={style1}>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2"  style={{color:"white",textDecoration:"none"}}>
           9-1-193/1, Regimental Bazaar,
          </li>
          {/* <li class="list-group-item bg-transparent border-0 p-0 mb-2">
            <a href="https://twitter.com/naologicerp"  style={{color:"white",textDecoration:"none"}}> Regimental Bazaar, </a>       
          </li >
           */}
          
      
          <li class="list-group-item bg-transparent border-0 p-0 mb-2"  style={{color:"white",textDecoration:"none"}}>
          Shivaji Nagar ,         </li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2"  style={{color:"white",textDecoration:"none"}}>
          Secunderabad – 500003,
          </li>
          <li class="list-group-item bg-transparent border-0 p-0 mb-2"  style={{color:"white",textDecoration:"none"}}>
           Telangana. (040) 40-175191
          </li>
        </ul>
        </div>
      </div>
        <div class="col-md-12">
          <div class="py-4 d-flex justify-content-center align-items-center">
            <a class="mr-4" href="https://camblissstudio.com/"  style={{color:"white",textDecoration:"none"}}>Designed & Developed by CAMBLISS PRIVATE LIMITED</a>
           
          </div>
        </div>
    </div>
  </div>
</footer></>
  );
}

export default Footer;